import { createSlice } from "@reduxjs/toolkit";

export const ENUM_MODALS_VALUE = {
  deposit: "deposit",
  premium: "premium",
  typeLogin: "typeLogin",
  private: "private",
  share: "share",
  register: "register",
  notify: "notify",
  messageLinkFrame: "messageLinkFrame",
  messageLinkOutsider: "messageLinkOutsider",
};

const parseLCS = (key, defVal) => {
  try {
    return JSON.parse(localStorage.getItem(key)) || defVal;
  } catch {
    return defVal;
  }
};

const commonSlice = createSlice({
  name: "common",
  initialState: {
    typeModel: +localStorage.getItem("typeModel") || 15,
    typeSort: localStorage.getItem("typeSort") || undefined,
    shown: parseLCS("shown", []),
    filter: parseLCS("filter", []),
    nickname: "",
    languagesAll: {},
    maxPage: 2,
    loader: false,
    broadcasters: [],
    broadcastersOnline: [],
    broadcastersPayload: {
      paging: { length: 16, page: 1 },
    },
    languages: [],
    flags: {
      foolScreen: false,
    },
    modals: {
      [ENUM_MODALS_VALUE.deposit]: false,
      [ENUM_MODALS_VALUE.premium]: false,
      [ENUM_MODALS_VALUE.typeLogin]: false,
      [ENUM_MODALS_VALUE.private]: false,
      [ENUM_MODALS_VALUE.cameraSetting]: false,
      [ENUM_MODALS_VALUE.share]: false,
      [ENUM_MODALS_VALUE.register]: false,
      [ENUM_MODALS_VALUE.notify]: false,
      [ENUM_MODALS_VALUE.messageLinkFrame]: false,
      [ENUM_MODALS_VALUE.messageLinkOutsider]: false,
    },
    controls: {
      volume: 100,
      zoom: 1,
      isFullscreenChat: true,
      isShown: true,
    },
  },
  reducers: {
    setTypeModel(state, newAction) {
      localStorage.setItem("typeModel", newAction.payload);
      state.typeModel = +newAction.payload;
    },
    setSortModel(state, newAction) {
      localStorage.setItem("typeSort", newAction.payload);
      state.typeSort = newAction.payload;
    },
    setShown(state, newAction) {
      localStorage.setItem("shown", JSON.stringify(newAction.payload));
      state.shown = newAction.payload;
    },
    setFilter(state, newAction) {
      localStorage.setItem("filter", JSON.stringify(newAction.payload));
      state.filter = newAction.payload;
    },
    setNickName(state, newAction) {
      state.nickname = newAction.payload;
    },
    setBroadCasters(state, newAction) {
      state.broadcasters = newAction.payload;
    },
    setBroadcastersOnline(state, newAction) {
      state.broadcastersOnline = newAction.payload;
    },
    setBroadcastersPayload(state, newAction) {
      state.broadcastersPayload = newAction.payload;
    },
    setLanguages(state, newAction) {
      state.languages = newAction.payload;
    },

    setModals(state, newAction) {
      const { name, value } = newAction.payload;
      state.modals[name] = value;
    },
    setFlags(state, newAction) {
      const { name, value } = newAction.payload;
      state.flags[name] = value;
    },
    setVolume(state, newAction) {
      state.controls.volume = newAction.payload;
    },
    setZoom(state, newAction) {
      state.controls.zoom = newAction.payload;
    },
    setShownControls(state, newAction) {
      state.controls.isShown = newAction.payload;
    },
    setFullscreenChat(state, newAction) {
      state.controls.isFullscreenChat = newAction.payload;
    },
    setMaxPage(state, newAction) {
      state.maxPage = newAction.payload;
    },
    setLoader(state, newAction) {
      state.loader = newAction.payload;
    },
    setLanguagesAll(state, newAction) {
      state.languagesAll = newAction.payload;
    },
  },
});

export default commonSlice.reducer;

export const {
  setTypeModel,
  setSortModel,
  setShown,
  setFilter,
  setNickName,
  setBroadCasters,
  setLanguages,
  setFlags,
  setModals,
  setVolume,
  setZoom,
  setFullscreenChat,
  setShownControls,
  setBroadcastersPayload,
  setBroadcastersOnline,
  setMaxPage,
  setLoader,
  setLanguagesAll,
} = commonSlice.actions;
